@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary-color: #5ce1e6;
}

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

@layer base {

    body {
        @apply font-[Lato];
        @apply font-light;
        @apply text-gray-100;
        @apply subpixel-antialiased;
    }
    
    nav li {
        @apply p-4;
        @apply cursor-pointer;
        @apply font-medium;
    }
}

@layer utilities {
    .section-style {
        @apply w-full;
        @apply bg-black;
        @apply px-8 py-16 md:py-24;
    }

    .section-title {
        @apply md:text-4xl text-2xl;
        @apply font-bold;
        @apply border-b-8;
        @apply border-primary;
        @apply inline; 
        @apply self-center;
        @apply text-center
    }

    {/* What We Do */}
    .wwd-item{
        @apply grid;
        @apply md:grid-cols-4;
        @apply gap-4 md:gap-8;
        @apply p-8;
        @apply border;
        @apply border-gray-800;
    }

    .wwd-item-title {
        @apply mb-2;
        @apply uppercase font-semibold;
        @apply self-center md:self-start;
    }

    .wwd-item-text {
        @apply md:col-span-3;
        @apply flex flex-col;
        @apply justify-center;
    }

    /* Advantages of Working With Us */
    .aowwu-item {
        @apply flex flex-col;
        @apply border border-gray-800;
        @apply p-8;
    }

    .aowwu-title{
        @apply uppercase font-semibold;
        @apply self-center;
    }

    .aowwu-text {
        @apply mt-2 md:mt-6;
        @apply marker:text-primary list-inside list-disc;
    }
    
    .aowwu-text li{
        @apply mb-2;
    }

    {/* Contact Us */}
    .cu-item {
        @apply flex flex-col;
        @apply items-center md:items-start;
    }
    .cu-item-title{
        @apply uppercase font-semibold;
        @apply mb-2;
    }

    .flat-btn {
        @apply border-2;
        @apply bg-primary;
        @apply border-primary;
        @apply font-black;
        @apply text-white;
        @apply px-6;
        @apply py-3;
        @apply flex;
        @apply items-center;
    }

}

.main {
    width: 100%;
    height: 100vh;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.main-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, .4);
}